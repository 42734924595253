import React from "react";
import InputCheckbox from "../../components/InputCheckbox/InputCheckbox";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Icon from "../../components/Icon/Icon";

function ComparisonViewSidebar({
	toggle,
	setIsLoading,
	setToggle,
	setHiddenRows,
	setHiddenColumns,
	metrics,
	contextData,
	toggleColumnVisibility,
	toggleRowVisibility,
	hiddenColumns,
	hiddenRows,
	setMetricsOrder = () => {},
	setLocationsOrder = () => {},
}) {
	const handleDragEnd = (result) => {
		setIsLoading(true);
		if (!result.destination) return;

		const { source, destination, type } = result;

		if (type === "metrics") {
			const items = Array.from(metrics);
			const [reorderedItem] = items.splice(source.index, 1);
			items.splice(destination.index, 0, reorderedItem);
			setMetricsOrder(items);
		} else if (type === "locations") {
			const items = Array.from(contextData.locations);
			const [reorderedItem] = items.splice(source.index, 1);
			items.splice(destination.index, 0, reorderedItem);
			setLocationsOrder(items);
		}
		setTimeout(() => {
			setIsLoading(false);
		}, 300);
	};

	return (
		<div className={`position-fixed table-toggle ${toggle ? "open" : "closed"}`}>
			<div
				onClick={() => setToggle(!toggle)}
				className='pointer'
				style={{
					fontSize: "34px",
					textAlign: "right",
					position: "relative",
					top: "-14px",
				}}
			>
				×
			</div>
			<DragDropContext onDragEnd={handleDragEnd}>
				<div className='column-visibility-toggles text-left cursor'>
					<h3 className='text-left'>Metrics</h3>
					<Droppable droppableId='metrics' type='metrics'>
						{(provided) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{metrics?.map((metric, index) => {
									const metricKey = Object.keys(metric)[0];
									const metricName = metric[metricKey];
									return (
										<Draggable key={metricKey} draggableId={metricKey} index={index}>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													className={`d-flex align-items-center ${
														snapshot.isDragging ? "dragging" : ""
													}`}
													style={{
														...provided.draggableProps.style,
														background: snapshot.isDragging ? "#f5f5f5" : "transparent",
													}}
												>
													<Icon
														className='border-0 mx-0 my-0'
														icon='expand-vertical-s-fill'
														size='md'
													/>
													<InputCheckbox
														label={`${metricName}`}
														isChecked={!hiddenColumns.has(metricKey)}
														onValueChange={() =>
															toggleColumnVisibility(metricKey, setHiddenColumns)
														}
														className='border-0 w-100'
													/>
												</div>
											)}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</div>

				<div className='row-visibility-toggles text-left'>
					<h3 className='text-left'>Locations</h3>
					<Droppable droppableId='locations' type='locations'>
						{(provided) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{contextData?.locations.map((location, index) => (
									<Draggable key={location._id} draggableId={location._id} index={index}>
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												className={`d-flex align-items-center ${
													snapshot.isDragging ? "dragging" : ""
												}`}
												style={{
													...provided.draggableProps.style,
													background: snapshot.isDragging ? "#f5f5f5" : "transparent",
												}}
											>
												<Icon
													className='border-0 mx-0 my-0'
													icon='expand-vertical-s-fill'
													size='md'
												/>
												<InputCheckbox
													label={`${location.location_name}`}
													isChecked={!hiddenRows.has(location._id)}
													onValueChange={() =>
														toggleRowVisibility(location._id, setHiddenRows)
													}
													className='border-0 w-100'
												/>
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</div>
			</DragDropContext>
		</div>
	);
}

export default ComparisonViewSidebar;
