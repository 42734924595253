import React from "react";

function ExpandVerticalSFill() {
	return (
		<g>
			<path d='M16.9493 7.44926L11.9995 2.49951L7.05228 7.44678L10.9995 7.44777L10.9995 16.5496L7.05032 16.5496L12 21.4995L16.9498 16.5498L12.9995 16.5497L12.9995 7.44827L16.9493 7.44926Z'></path>
		</g>
	);
}

export default ExpandVerticalSFill;
