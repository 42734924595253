import tinycolor from "tinycolor2";

export function hueRotate(color, degrees) {
	// Convert color string to tinycolor object
	const tc = tinycolor(color);

	// Rotate hue
	const rotated = tc.spin(degrees);

	// Return hex code of rotated color
	return rotated.toHexString();
}

export const brandColors = ["#4767E5", "#426B5D", "#FF708B", "#9072D5", "#FFBA69"];
