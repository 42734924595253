import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDashboard } from "../../redux/location/locationActions";
import { removeLocationPDFUploadedFile } from "../../services/dashboards/townson-dashboard.services/townson-dashboard-data";
import AddSocCode from "../../pages/townson-dashboard-list/components/client-data-upload-component/add-soc-code";
import AddSocCodeOnet from "../../pages/townson-dashboard-list/components/client-data-upload-component/add-soc-code-search-onet";
import CSVUpload from "../../pages/townson-dashboard-list/components/client-data-upload-component/csv-upload";
import CustomersUpload from "../../pages/townson-dashboard-list/components/client-data-upload-component/customers-upload";
import { isSearchJobTitleEnabled } from "../../redux/user/utils";
import Loader from "../loader/loader.component";
import {
	removeUploadedCsv,
	validatePDFFiles,
} from "../../services/dashboards/townson-dashboard.services/townson-dashboard-data";
import { checkValueIsPositiveDecimal } from "../../utils/constants";
import InputToggle from "../InputToggle/InputToggle";

import CleanModal from "../modal/CleanModal";
import {
	createDashBoard,
	getSocCodeList,
	handleCustomersOnChangeFile,
	handleOnChangeFile,
	handleOnChangeLocationFile,
	handleOnCurrentLocationDescriptionChange,
	handleSocCodeUpdate,
	handleToggleCheckboxSocCode,
	resumeProcessing,
	uploadCSV,
	uploadCustomers,
	validateStep,
	checkUrlsForMaliciousContent,
	handleLocationPDFOnChangeFile,
	removeLocationPDFS,
	removeUploadedFile,
	getInitialStepOneData,
	getInitialValidationErrorState,
	getStepTitle,
	getModalCustomButtons,
} from "./CreateCloneBusinessInsightsDashboard.helper";
import AddEditLocations from "./locations/AddEditLocations";
import DashboardTitle from "./title/DashboardTitle";
import { CSVApprovalScreen } from "./locations/CSVApprovalScreen";

function CreateCloneBusinessInsightsDashboard({
	openBiModal,
	setOpenBiModal,
	title,
	screenLocation = "score-insights",
}) {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const maxBiUploads = useSelector((state) => state.user.company_bi_upload_limit);

	const maxAllowed = maxBiUploads;
	const arrayLength = maxAllowed;
	const dynamicArray = Array.from({ length: arrayLength }, (_, index) => index);

	// Primary state
	const [proposedLocationIndex, setProposedLocationIndex] = useState(1);
	const [location, setLocation] = useState({
		currentLocation: {},
		proposedLocations: [{}],
	});
	const [locationDescription, setLocationDescription] = useState([]);
	const [step, setStep] = useState(1);
	const [officeLocationType, setLocationType] = useState(false);

	// Dashboard related state
	const currentDashboardData =
		screenLocation === "business-insights"
			? useSelector((state) => state.location.location)
			: useSelector((state) => state.scoreInsightsDashboard.currentDashboard);
	const siLoading = useSelector((state) => state.scoreInsightsDashboard.loading);
	const biLoading = useSelector((state) => state.location.loading);

	const [dashboardTitle, setDashboardTitle] = useState("");
	const [dashboardData, setDashboardData] = useState([{}]);

	const [stepOneData, setStepOneData] = useState(getInitialStepOneData());

	// File upload states
	const [csvFileName, setCsvFileName] = useState();
	const [csvFile, setCsvFile] = useState();
	const [customersFile, setCustomersFile] = useState();
	const [progressFileUpload, setProgressFileUpload] = useState(0);
	const [errorFileUpload, setErrorFileUpload] = useState();
	const [customersFileName, setCustomersFileName] = useState();
	const [customersProgressFileUpload, setCustomersProgressFileUpload] = useState(0);
	const [customersErrorFileUpload, setCustomersErrorFileUpload] = useState();
	const [locationPDFFilesUpload, setlocationPDFFilesUpload] = useState(0);
	const [locationPDFFiles, setLocationPDFFiles] = useState([]);
	const [locationPDFFilesErrorUpload, setLocationPDFFilesErrorUpload] = useState();
	const [locationPDFFilesNames, setLocationPDFFilesNames] = useState([]);
	const [photos, setPhotos] = useState([{}]);

	// SOC Code states
	const [useOnetSearch, setUseOnetSearch] = useState(false);
	const [selectedSocCode, setSelectedSocCode] = useState([]);
	const [socCodes, setSocCodes] = useState([]);
	const [socCodeList, setSocCodeList] = useState([]);
	const [errorSOCValidation, setErrorSOCValidation] = useState();

	const [currentLocationDescription, setCurrentLocationDescription] = useState({});
	const [validationError, setValidationError] = useState(getInitialValidationErrorState());

	const [loading, setLoading] = useState(false);
	const [trigger, setTrigger] = useState(false);

	// CSV related state
	const [csvError, setCsvError] = useState([]);
	const [approvingCsvUpload, setApprovingCsvUpload] = useState(false);
	const [proceedWithCsvUpload, setProceedWithCsvUpload] = useState(false);
	const [csvProcessingLoading, setCSVProcessingLoading] = useState(false);
	const [tempResults, setTempResults] = useState([]);
	const [tempData, setTempData] = useState();
	const [csvApproval, setCsvApproval] = useState(null);
	const [numberOfRows, setNumberOfRows] = useState(0);

	useEffect(() => {
		if (screenLocation === "business-insights") {
			setDashboardTitle(currentDashboardData.dashboard_title ?? "");
			setLocationType(currentDashboardData?.is_industrial_location_type);
			if (currentDashboardData?.soc_codes) {
				setSelectedSocCode(currentDashboardData?.soc_codes);
			}
		} else {
			if (currentDashboardData && currentDashboardData?.name) {
				setDashboardTitle("Cloned from Score Insights - " + currentDashboardData?.name);
				setSelectedSocCode(currentDashboardData?.soc);
			}
		}
		setDashboardData(currentDashboardData);
	}, [currentDashboardData, screenLocation]);

	useEffect(() => {
		setCsvApproval(null);
		setCsvError([]);
		setApprovingCsvUpload(false);
		setCSVProcessingLoading(false);
		setProgressFileUpload(0);
		setCustomersFile();
		setCsvFile();
		setCsvFileName();
		setErrorFileUpload();
		setCustomersFileName();
		if (screenLocation === "business-insights") {
			setStep(1);
			setSelectedSocCode([]);
			setLocationDescription([]);
			setlocationPDFFilesUpload(0);
			setLocationPDFFiles([]);
			setLocationPDFFilesNames([]);
			setTempResults([]);
			setTempData();

			setValidationError(getInitialValidationErrorState());

			if (biLoading || siLoading) {
				setDashboardData({});
				setLocationType(false);
				setDashboardTitle("");
				setSelectedSocCode([]);
				setStepOneData({
					location: {
						currentLocation: {},
						proposedLocations: [{}],
					},
					locationDescription: [],
					proposedLocationIndex: 1,
				});
				setLocation({
					currentLocation: {},
					proposedLocations: [{}],
				});
			}
			setLocation((prevLocation) => ({
				...prevLocation,
				proposedLocations: [
					{
						address: { freeformAddress: "" },
						position: { is_current: false, lat: null, lon: null },
					},
				],
			}));
		}
	}, [openBiModal]);

	useEffect(() => {
		if (!approvingCsvUpload) {
			setCsvApproval(null);
		}
	}, [approvingCsvUpload]);

	useEffect(() => {
		if (proceedWithCsvUpload) {
			resumeProcessing(
				setCSVProcessingLoading,
				setCsvError,
				setLocation,
				setProposedLocationIndex,
				setLocationDescription,
				tempResults,
				maxAllowed,
				tempData,
			);
		}
	}, [proceedWithCsvUpload, maxAllowed, tempData, tempResults]);

	useEffect(() => {
		getSocCodeList(setSocCodeList);
	}, []);

	useEffect(() => {
		if (step === 1 && stepOneData.location.proposedLocations.length > 0) {
			setLocation(stepOneData.location);
			setLocationDescription(stepOneData.locationDescription);
			setProposedLocationIndex(stepOneData.proposedLocationIndex);
		}
	}, [step, stepOneData]);

	const handleOnDashboardTitleChange = (e) => {
		setDashboardTitle(e.target.value);
	};

	const handleNextStep = async () => {
		if (validateStep(validationError, setValidationError, step, dashboardTitle, location, selectedSocCode)) {
			if (step === 1) {
				setStepOneData({
					location,
					locationDescription,
					proposedLocationIndex,
				});
			}
			if (step === 4) {
				try {
					await createDashBoard(
						dashboardTitle,
						location,
						useOnetSearch,
						currentLocationDescription,
						csvFile,
						customersFile,
						locationPDFFilesNames,
						locationDescription,
						selectedSocCode,
						setLoading,
						setTrigger,
						setStep,
						setOpenBiModal,
						currentDashboardData,
						officeLocationType,
					);

					dispatch({ type: "CLEAR_LOCATION" });

					setOpenBiModal(false);
				} catch (error) {
					console.error("Error creating/updating dashboard:", error);
				}
			} else {
				setStep((prevStep) => Math.min(4, prevStep + 1));
			}
		}
	};

	const removeCSV = async (path) => {
		const formData = new FormData();
		formData.append("employee_csv_path", path);
		const res = await removeUploadedCsv(formData);
	};

	/**
	 * Uploads PDF files for a specific location one at a time to avoid exceeding the file upload max size limit.
	 * Tracks the progress of each file upload.
	 *
	 * @param {string} location_index - The index of the location to which the files belong.
	 * @param {FileList | File[]} files - A list of files to be uploaded for the specified location.
	 *
	 * @returns {Promise<void>} - This function does not return a value but updates state variables to reflect the upload progress or errors.
	 */
	const uploadLocationPDFS = async (location_index, files) => {
		if (!files) return;

		let uploadedFiles = [];
		let uploadProgress = []; // Track progress of each file

		// Process each file one at a time to avoid exceeding the file upload max size limit
		for (const [index, file] of Array.from(files).entries()) {
			const formData = new FormData();
			formData.append(`files[${location_index}][${index}]`, file);

			// Create a config object for tracking upload progress
			const config = {
				onUploadProgress: (progressEvent) => {
					uploadProgress[index] = progressEvent;
					setlocationPDFFilesUpload({ ...uploadProgress });
				},
			};

			const res = await validatePDFFiles(formData, config.onUploadProgress);

			if (res?.status === "success") {
				const uploadedFile = res?.data[0];
				uploadedFiles.push(uploadedFile);
			} else {
				setLocationPDFFilesErrorUpload(res.message); // Set error message
				break; // Stop further uploads if one fails
			}
		}

		// Update state with the final list of uploaded files
		if (uploadedFiles.length > 0) {
			setLocationPDFFilesNames((prevFiles) => {
				const updatedFiles = prevFiles.map((locationFile) => {
					if (locationFile.location_index === location_index) {
						return { ...locationFile, files: uploadedFiles };
					}
					return locationFile;
				});

				return [...updatedFiles];
			});

			// Force an immediate UI update to show new file URLs instead of original names
			setTimeout(() => {
				setLocationPDFFiles(() => [...uploadedFiles]);
			}, 0);
		}
	};

	const handleLocationPDFFiles = (locationFiles) => {
		handleLocationPDFOnChangeFile(
			locationFiles,
			setLocationPDFFilesErrorUpload,
			setLocationPDFFilesNames,
			(location_index, files) =>
				uploadLocationPDFS(
					location_index,
					files,
					setlocationPDFFilesUpload,
					setLocationPDFFilesErrorUpload,
					setLocationPDFFilesNames,
					setLocationPDFFiles,
					validatePDFFiles,
				),
			(files) =>
				removeLocationPDFS(
					files,
					removeLocationPDFUploadedFile,
					dispatch,
					getDashboard,
					currentDashboardData?.id,
				),
			setLocationPDFFiles,
		);
	};

	const handleRemovePDFFile = (file) => {
		removeLocationPDFS(file, removeLocationPDFUploadedFile, dispatch, getDashboard, currentDashboardData?.id);
	};

	const handleCancelPDFUpload = (fileToRemove) => {
		removeUploadedFile(fileToRemove, locationPDFFilesNames, setLocationPDFFilesNames);
	};

	const renderStepContent = () => {
		switch (step) {
			case 1:
				return (
					<>
						{csvApproval !== null && approvingCsvUpload === true && proceedWithCsvUpload === false ? (
							<div style={{ width: "100%" }} className='form-row csvApproval'>
								<div>
									<h6>Please review imported locations before processing</h6>
									{numberOfRows > maxAllowed && (
										<p className='text-danger'>
											The uploaded file contains {numberOfRows} rows, exceeding the maximum limit
											of {maxAllowed} rows. {numberOfRows - maxAllowed} row(s) were skipped.
										</p>
									)}
									<CSVApprovalScreen
										csvProcessingLoading={csvProcessingLoading}
										csvApproval={csvApproval}
									/>
								</div>
							</div>
						) : (
							<>
								{csvProcessingLoading && <Loader />}

								<DashboardTitle
									dashboardTitle={dashboardTitle}
									handleOnDashboardTitleChange={handleOnDashboardTitleChange}
									error={validationError.title.error}
									errorMessage={validationError.title.message}
									officeLocationType={officeLocationType}
									setLocationType={setLocationType}
								/>
								<AddEditLocations
									photos={photos}
									setPhotos={setPhotos}
									officeLocationType={officeLocationType}
									screenLocation={screenLocation}
									dashboardData={dashboardData}
									removePDFFile={handleRemovePDFFile}
									handleOnCurrentLocationDiscriptionChange={(name, value) =>
										handleOnCurrentLocationDescriptionChange(
											name,
											value,
											currentLocationDescription,
											setCurrentLocationDescription,
											checkUrlsForMaliciousContent,
										)
									}
									cancelPDFUpload={handleCancelPDFUpload}
									errorFileUpload={errorFileUpload}
									dynamicArray={dynamicArray}
									proposedLocations={location}
									pdfFileName={locationPDFFilesNames}
									handleLocationPDFFiles={handleLocationPDFFiles}
									proposedLocationIndex={proposedLocationIndex}
									location={location}
									setLocation={setLocation}
									locationDescription={locationDescription}
									setLocationDescription={setLocationDescription}
									setProposedLocationIndex={setProposedLocationIndex}
									checkValueIsPositiveDecimal={checkValueIsPositiveDecimal}
									maxLengthTextArea={500}
									maxAllowed={maxAllowed}
									handleOnChangeFile={(event) =>
										handleOnChangeLocationFile(
											event,
											setApprovingCsvUpload,
											setProceedWithCsvUpload,
											setCsvError,
											setTempData,
											setCSVProcessingLoading,
											setCsvApproval,
											setTempResults,
											setNumberOfRows,
											maxAllowed,
											officeLocationType,
										)
									}
									csvError={csvError}
									error={validationError.location.error}
									errorMessage={validationError.location.message}
									progressFileUpload={locationPDFFilesUpload}
								/>
							</>
						)}
					</>
				);

			case 2:
				return (
					<CSVUpload
						csvFileName={csvFileName}
						progressFileUpload={progressFileUpload}
						errorFileUpload={errorFileUpload}
						handleOnChangeFile={(event) =>
							handleOnChangeFile(
								event,
								setCsvFile,
								setCsvFileName,
								setErrorFileUpload,
								(file) =>
									uploadCSV(
										file,
										setProgressFileUpload,
										setCsvFile,
										setCsvFileName,
										setErrorFileUpload,
									),
								() => removeCSV(csvFile),
							)
						}
					/>
				);

			case 3:
				return (
					<CustomersUpload
						csvFileName={customersFileName}
						progressFileUpload={customersProgressFileUpload}
						errorFileUpload={customersErrorFileUpload}
						handleOnChangeFile={(event) =>
							handleCustomersOnChangeFile(
								event,
								setCustomersErrorFileUpload,
								setCustomersFileName,
								setCustomersFile,
								(file) =>
									uploadCustomers(
										file,
										setCustomersProgressFileUpload,
										setCustomersFile,
										setCustomersFileName,
										setCustomersErrorFileUpload,
									),
								() => removeCustomers(customersFile),
							)
						}
					/>
				);

			case 4:
				return (
					<>
						{validationError.socCode.error && (
							<div>
								<span className='sub-text' style={{ color: "red" }}>
									* {validationError.socCode.message}
								</span>
							</div>
						)}
						{isSearchJobTitleEnabled(user) ? (
							<InputToggle
								labelClass='text-dark'
								label='Enable "search by job title"'
								footNote='Allows searching by Job Title and Occupation'
								isChecked={useOnetSearch}
								onValueChange={() => {
									setUseOnetSearch(!useOnetSearch);
									setSelectedSocCode([]);
								}}
							/>
						) : null}
						{useOnetSearch ? (
							<AddSocCodeOnet
								socCodes={socCodes}
								setSocCodes={(newSocCodes) => setSocCodes(newSocCodes)}
								selectedSocCode={selectedSocCode}
								setSelectedSocCode={(newSelectedSocCode) =>
									handleSocCodeUpdate(newSelectedSocCode, setSelectedSocCode)
								}
								setSelectedSOCCodes={(newSelectedSocCode) =>
									handleSocCodeUpdate(newSelectedSocCode, setSelectedSocCode)
								}
								errorSOCValidation={errorSOCValidation}
								dashboardDataSocs={[]}
								selectedSOCCodes={selectedSocCode}
							/>
						) : (
							<AddSocCode
								socCodeList={socCodeList}
								selectedSocCode={selectedSocCode}
								handleToggleCheckboxSocCode={(code) =>
									handleToggleCheckboxSocCode(code, selectedSocCode, setSelectedSocCode)
								}
								errorSOCValidation={errorSOCValidation}
							/>
						)}
					</>
				);

			default:
				return null;
		}
	};

	return (
		<CleanModal
			key={"make-bi-dashboard"}
			title={getStepTitle(step, title)}
			trigger={openBiModal}
			setTrigger={setOpenBiModal}
			customButton={getModalCustomButtons({
				step,
				csvApproval,
				approvingCsvUpload,
				proceedWithCsvUpload,
				setProceedWithCsvUpload,
				setApprovingCsvUpload,
				setCSVProcessingLoading,
				validationError,
				setStep,
				handleNextStep,
				currentDashboardData,
			})}
		>
			{renderStepContent()}
		</CleanModal>
	);
}

export default CreateCloneBusinessInsightsDashboard;
