import React from "react";

function ArrowDropLeftLine() {
	return (
		<g>
			<path d='M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z'></path>
		</g>
	);
}

export default ArrowDropLeftLine;
