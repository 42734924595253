import { axiosPost } from "../axios/config";

const prefix = "/api/v2/business-insights/";

export const getComparisonMetrics = async (data) => {
	const response = await axiosPost(`${prefix}get-metrics`, data);
	return response;
};

export const updateComparisonMetrics = async (data) => {
	const response = await axiosPost(`${prefix}update-metrics`, data);
	return response;
};
