import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Loader from "../../components/loader/loader.component";
import { activateCollaboration } from "../../services/dashboards/users.service";
function Collaborate() {
	const [searchParams] = useSearchParams();
	const dashboardType = searchParams.get("dashboardType");
	const collaborationInvitationToken = searchParams.get("collaborationInvitationToken");
	const dashboardId = searchParams.get("dashboardId");
	const [loader, setLoader] = useState(true);
	const navigate = useNavigate();

	const details = {
		dashboardId: dashboardId,
		collaborationInvitationToken: collaborationInvitationToken,
		dashboardType: dashboardType,
	};
	const checkActivation = async (invitationDetails) => {
		activateCollaboration(invitationDetails).then((response) => {
			if (response.message.includes("success")) {
				setLoader(false);
				if (response.data.collaboration.type === "business-insights") {
					navigate(`/${response.data.collaboration.type}?id=${response.data.collaboration.id}`);
				} else {
					navigate(
						`/${response.data.collaboration.type}/total-market-score/${response.data.collaboration.id}`,
					);
				}
			} else {
				setLoader(false);
			}
		});
	};

	useEffect(() => {
		checkActivation(details);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return loader ? (
		<Loader />
	) : (
		<div className='container login-sec'>
			<div className='bg-pattern'>
				<img src='assets/images/login-pattern.svg' alt='' />
			</div>
			<div className='row title-filter-row position-relative'>
				<div
					style={{
						top: 0,
						bottom: 0,
						margin: "auto",
						width: "550px",
						left: 0,
						right: 0,
						textAlign: "center",
						height: "150px",
					}}
					className='col col-12'
				>
					<p className='card' style={{ padding: "32px", background: "white" }}>
						This invitation has expired or is invalid. <br></br>Please contact the sender and request
						another invitation
					</p>
				</div>
			</div>
		</div>
	);
}

export default Collaborate;
