import React from "react";
import { addCommas } from "../../../helper/number.helpers";

function CSVApprovalScreen({ csvApproval }) {
	return (
		<>
			<ul className='csvRows'>
				{csvApproval.map((csvRow, i) => (
					<ol key={i} className='group-field'>
						{csvRow.Location && (
							<div>
								<b>Location: </b> {csvRow.Location}
							</div>
						)}
						{csvRow.status && (
							<div>
								<b>Status:</b> {csvRow.status}
							</div>
						)}
						{csvRow.leasing_company && (
							<div>
								<b>Leasing company:</b> {csvRow.leasing_company}
							</div>
						)}
						{csvRow.is_current && (
							<div>
								<b>Current Location:</b> {csvRow.is_current ? "Yes" : "No"}
							</div>
						)}
						{csvRow.building_sf && (
							<div>
								<b>Building SF:</b> {addCommas(csvRow.building_sf)}
							</div>
						)}
						{csvRow.available_sf && (
							<div>
								<b>Available SF:</b> {addCommas(csvRow.available_sf)}
							</div>
						)}

						{/* Industrial-specific fields */}
						{csvRow.office_sf && (
							<div>
								<b>Office SF:</b> {addCommas(csvRow.office_sf)}
							</div>
						)}
						{csvRow.clear_height && (
							<div>
								<b>Clear Height:</b> {csvRow.clear_height}
							</div>
						)}
						{csvRow.car_trailer_parking && (
							<div>
								<b>Car/Trailer Parking:</b> {csvRow.car_trailer_parking}
							</div>
						)}
						{csvRow.docs_grade_level_doors && (
							<div>
								<b>Docks/Grade Level Doors:</b> {csvRow.docs_grade_level_doors}
							</div>
						)}

						{/* Office-specific fields */}
						{csvRow.year_built && (
							<div>
								<b>Year Built:</b> {csvRow.year_built}
							</div>
						)}
						{csvRow.parking_ratio && (
							<div>
								<b>Parking Ratio:</b> {csvRow.parking_ratio}
							</div>
						)}
						{csvRow.location_suite && (
							<div>
								<b>Location Suite:</b> {csvRow.location_suite}
							</div>
						)}
						{csvRow.percent_leased && (
							<div>
								<b>Percent Leased:</b> {csvRow.percent_leased}
							</div>
						)}
						{csvRow.electric_price_sf && (
							<div>
								<b>Electric Price SF:</b> ${csvRow.electric_price_sf}
							</div>
						)}

						{/* Common financial fields */}
						{csvRow.base_rent && (
							<div>
								<b>Base Rent:</b> ${csvRow.base_rent}
							</div>
						)}
						{csvRow.opex_value && (
							<div>
								<b>Opex: </b> ${csvRow.opex_value}
							</div>
						)}
						{csvRow.gross_price_sf && (
							<div>
								<b>Gross PSF:</b> ${csvRow.gross_price_sf}
							</div>
						)}
						{csvRow.notes && (
							<div>
								<b>Notes: </b> {csvRow.notes}
							</div>
						)}
					</ol>
				))}
			</ul>
		</>
	);
}

export { CSVApprovalScreen };
