import React from "react";
import InputToggle from "../../InputToggle/InputToggle";
import InputText from "../../InputText/InputText";

function DashboardTitle({
	error,
	errorMessage,
	handleOnDashboardTitleChange,
	dashboardTitle,
	officeLocationType,
	setLocationType,
}) {
	return (
		<div className='w-full mb-7'>
			<div className='relative mb-0 card-title'>
				<label className='relative left-0 mr-0 top-0 mb-2 text-2xl' htmlFor='dashboardTitle'>
					Dashboard Title
				</label>
				{error && <span className='sub-text text-red-500'>*{errorMessage}</span>}
			</div>

			<div className='pointer d-flex align-items-center' style={{ gap: "10px" }}>
				<p
					onClick={() => {
						setLocationType(!officeLocationType);
					}}
				>
					Office
				</p>
				<InputToggle
					className='toggle-radius'
					trueColor={"#5c86c1"}
					falseColor={"#5c86c1"}
					label={""}
					name='hideCustomerLocations'
					isChecked={officeLocationType}
					onValueChange={(e) => {
						setLocationType(!officeLocationType);
					}}
				/>
				<p
					onClick={() => {
						setLocationType(!officeLocationType);
					}}
				>
					Industrial
				</p>
			</div>
			<div className='input-item'>
				<InputText
					id='dashboardTitle'
					value={dashboardTitle}
					type='text'
					name='dashboard_title'
					required={true}
					invalid={error}
					invalidMessage={errorMessage}
					onValueChange={(value) => handleOnDashboardTitleChange({ target: { value } })}
				/>
			</div>
		</div>
	);
}

export default DashboardTitle;
