import React from "react";
import { Outlet } from "react-router-dom";

function ComparisonLayout() {
	return (
		<div className='site-content clean-layout comparison-layout'>
			<Outlet />
		</div>
	);
}

export default ComparisonLayout;
