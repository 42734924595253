import "./Accordion.scss";
import { useState } from "react";
const Accordion = ({ collapsedTitle, children, expandedTitle = collapseTitle }) => {
	const [isOpen, setOpen] = useState(false);
	return (
		<div className='accordion'>
			<div className='accordion-wrapper'>
				<div className={`accordion--title ${isOpen ? "open" : ""}`} onClick={() => setOpen(!isOpen)}>
					{isOpen ? expandedTitle : collapsedTitle}
				</div>
				<div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
					<div className='accordion-content'>{children}</div>
				</div>
			</div>
		</div>
	);
};

export default Accordion;
