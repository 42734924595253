// components/Map/Map.jsx

import "./Map.styles.scss";

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactMapGL, { FullscreenControl, GeolocateControl, NavigationControl, ScaleControl } from "react-map-gl";
import { useSelector } from "react-redux";
import mapboxgl from "mapbox-gl";

import LoadingOverlay from "../loader/loader.component";

/**
 * @typedef {Object} ViewportConfigProps
 * @property [latitude] {number}
 * @property [longitude] {number}
 * @property [zoom] {number}
 * @property [bearing] {number}
 * @property [pitch] {number}
 */

/** @type ViewportConfigProps */
const VIEWPORT_CONFIG_DEFAULTS = {
	latitude: 37.0902,
	longitude: -95.7129,
	zoom: 5,
};

/**
 * A Map component using react-map-gl
 * @param {JSX.Element | JSX.Element[] | string | string[]} [children] -
 * @param {ViewportConfigProps} [viewportConfig] - Sets the initial state of the map.
 * @param {boolean} showGeolocateControl - Toggles the Geolocation map control.
 * @param {boolean} showFullscreenControl - Toggles the Fullscreen map control.
 * @param {boolean} showNavigationControl - Toggles the Navigation map control.
 * @param {boolean} showScaleControl - Toggles the Scale map control
 * @param {"top-left" | "top-right" | "bottom-right" | "bottom-left" } controlPosition - Sets the position of the map controls.
 * @param {boolean} loading - Toggles loading state.
 * @param {?Coordinates} centerOnCoordinates - Update value to center the map on the `Coordinates`.
 * @param {?CoordinateArray} fitToBounds - Update value to center and zoom map to fit around provided `CoordinateArray`.
 * @param {number | Object} mapPadding - the min padding to use around the map.  Can be a padding object: {top: 10, right: 15}
 * @param {GeoJSON[]} shapes
 * @return {JSX.Element}
 * @constructor
 */
const MapboxMap = ({
	onMapLoad,
	children,
	viewportConfig = VIEWPORT_CONFIG_DEFAULTS,
	showGeolocateControl = false,
	showFullscreenControl = false,
	showNavigationControl = false,
	showScaleControl = false,
	controlPosition = "top-left",
	loading = false,
	centerOnCoordinates = null, // lat and lon coordinates
	fitToBounds = null, // array of coordinates to zoom to.
	mapPadding = 15,
	...props
}) => {
	const MAPBOX_ACCESS_TOKEN = import.meta.env.VITE_REACT_APP_MAPBOX_API_KEY;
	const MAP_THEME_LIGHT = "mapbox://styles/bluechipinsights/cl0s3m6ir000x15qnsdgj967q";
	const MAP_THEME_DARK = "mapbox://styles/bluechipinsights/cl0s5ahni000314ulbdedlu90";

	const mapRef = useRef();

	/** @type {{ theme_color: "light-theme" | "dark-theme" }} - The Theme object from redux */
	const { theme_color } = useSelector((state) => state.theme);

	/** @type {string} - The theme color from redux */
	const mapTheme = useMemo(() => {
		return theme_color === "dark-theme" ? MAP_THEME_DARK : MAP_THEME_LIGHT;
	}, [theme_color]);

	const [viewState, setViewState] = useState({ ...VIEWPORT_CONFIG_DEFAULTS, ...viewportConfig });

	useEffect(() => {
		if (centerOnCoordinates) {
			mapRef.current.flyTo({
				center: centerOnCoordinates,
			});
		}
	}, [centerOnCoordinates]);

	const fitMapToCoordinateArray = useCallback(
		(padding = mapPadding) => {
			if (fitToBounds && fitToBounds.length > 1) {
				let minLng = Infinity;
				let maxLng = -Infinity;
				let minLat = Infinity;
				let maxLat = -Infinity;

				// Loop through the array and update the min and max values
				for (const [lng, lat] of fitToBounds) {
					minLng = Math.min(minLng, lng);
					maxLng = Math.max(maxLng, lng);
					minLat = Math.min(minLat, lat);
					maxLat = Math.max(maxLat, lat);
				}

				mapRef?.current?.fitBounds(
					[
						[minLng, minLat],
						[maxLng, maxLat],
					],
					{ padding: padding || 10 },
				);
			}
		},
		[mapRef, fitToBounds, mapPadding],
	);

	useEffect(() => {
		fitMapToCoordinateArray();
	}, [fitMapToCoordinateArray, fitToBounds]);

	const fitBoundsWithPadding = useCallback((coordinates, padding) => {
		if (mapRef.current) {
			const bounds = new mapboxgl.LngLatBounds();
			coordinates.forEach((coord) => bounds.extend(coord));
			const map = mapRef.current.getMap();
			if (map) {
				map.fitBounds(bounds, {
					padding,
					duration: 0,
				});
			}
		}
	}, []);

	useEffect(() => {
		if (mapRef.current && onMapLoad) {
			onMapLoad(fitBoundsWithPadding);
		}
	}, [onMapLoad, fitBoundsWithPadding]);

	props.onMapLoad?.(fitBoundsWithPadding);

	return (
		<ReactMapGL
			ref={mapRef}
			{...viewState}
			mapStyle={mapTheme}
			preserveDrawingBuffer={true}
			mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
			onLoad={() => {
				if (mapRef.current && onMapLoad) {
					onMapLoad(fitBoundsWithPadding);
				}
			}}
			onMove={(evt) => setViewState(evt.viewState)}
			onRender={(event) => event.target.resize()}
			{...props}
		>
			{showGeolocateControl && <GeolocateControl position={controlPosition} />}
			{showFullscreenControl && <FullscreenControl position={controlPosition} />}
			{showNavigationControl && <NavigationControl position={controlPosition} />}
			{showScaleControl && <ScaleControl />}

			{children}

			{loading && <LoadingOverlay text={"Loading..."} />}
		</ReactMapGL>
	);
};

export default MapboxMap;
