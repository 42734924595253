import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = {
	message: "",
	data: "",
	type: "",
};

export const toastSlice = createSlice({
	name: "toast",
	initialState,
	reducers: {
		ToastShow: (state, action) => {
			state.message = action.payload.message;
			state.data = action.payload;
			state.type = action.payload.type;
		},
	},
});

const selectToastMessage = (state) => state.toast.message;
const selectToastType = (state) => state.toast.type;
const selectToastData = (state) => state.toast.data;

export const toastSelector = createSelector([selectToastMessage, selectToastType], (message, type) => ({
	message,
	type,
}));

export const toastError = createSelector([selectToastData, selectToastType], (data, type) => {
	const dataError = data?.dataError ? data.dataError : {};
	return { data: dataError, type };
});

const { actions, reducer } = toastSlice;

export const { ToastShow } = actions;

export default reducer;
