import { axiosGet, axiosPost, axiosUploadFormData } from "../../../axios/config";
import { GET_DASHBOARDS } from "../../../redux/score-insights/scoreInsightsTypes";

const prefixV2 = import.meta.env.VITE_REACT_APP_API_URL + "/api/v2/business-insights/";

export const validateCSVFile = async (formData, setProgressFileUpload) => {
	const response = await axiosUploadFormData(`${prefixV2}validate-csv`, formData, setProgressFileUpload);
	return response;
};

export const validatePDFFiles = async (formData, setProgressFileUpload) => {
	return await axiosUploadFormData(`${prefixV2}validate-pdf`, formData, setProgressFileUpload);
};

export const removeUploadedCsv = async (data) => {
	const response = await axiosUploadFormData(`${prefixV2}cancel-csv`, data);
	return response;
};

export const removeCustomersUploadedCsv = async (data) => {
	const response = await axiosUploadFormData(`${prefixV2}cancel-csv`, data);
	return response;
};

export const removeLocationPDFUploadedFile = async (data) => {
	return await axiosUploadFormData(`${prefixV2}cancel-pdf`, data);
};
export const removeLocationPDFPhotos = async (data) => {
	return await axiosPost(`${prefixV2}remove-pdf-photos`, data);
};
export const getPhotos = async (data) => {
	return await axiosPost(`${prefixV2}get-pdf-photos`, data);
};

export const updatePhotos = async (data) => {
	return await axiosPost(`${prefixV2}update-pdf-photos`, data);
};


export const updateScvData = async (data) => {
	const response = await axiosUploadFormData(`${prefixV2}update-dashboard`, data);
	return response;
};

export const getSOCCode = async () => {
	const response = await axiosGet(`${prefixV2}soc-code`);
	return response;
};

export const getDashboardList = async () => {
	const response = await axiosPost(`${prefixV2}dashboard`);
	return response;
};

export const getDashboardStatusList = async () => {
	const response = await axiosGet(`${prefixV2}dashboard-status`);
	return response;
};

export const getDashboardDetails = async (data) => {
	const response = await axiosPost(`${prefixV2}dashboard-details`, data);
	return response;
};

export const deleteDashboard = async (data) => {
	const response = await axiosPost(`${prefixV2}delete-dashboard`, data);
	return response;
};

export const cloneDashboard = async (data) => {
	const response = await axiosPost(`${prefixV2}clone-dashboard`, data);
	return response;
};

export const getCenterOfGravity = async (data) => {
	const response = await axiosPost(`${prefixV2}center-of-gravity`, data);
	return response;
};

export const getCustomerCenterOfGravity = async (data) => {
	const response = await axiosPost(`${prefixV2}customer-center-of-gravity`, data);
	return response;
};

export const getCenterOfGravityStatus = async (data) => {
	const response = await axiosPost(`${prefixV2}center-of-gravity-status`, data);
	return response;
};

export const getMosaicHouseHoldData = async (data) => {
	const response = await axiosPost(`${prefixV2}employee-mosaic-household`, data);
	return response;
};

export const getSOCCodeHeatmap = async (formData) => {
	const response = await axiosUploadFormData(`${prefixV2}soc-code-heatmap`, formData);
	return response;
};

export const createDashboard = async (formData) => {
	const response = await axiosUploadFormData(`${prefixV2}create-dashboard`, formData);
	return response;
};

export const editDashboardDetails = async (formData) => {
	const response = await axiosUploadFormData(`${prefixV2}edit-dashboard-details`, formData);
	return response;
};

export const updateProposedLocation = async (formData) => {
	const response = await axiosUploadFormData(`${prefixV2}update-proposed-location`, formData);
	return response;
};

export const addProposedLocationFromMap = async (formData) => {
	const response = await axiosUploadFormData(`${prefixV2}add-location-from-map`, formData);
	return response;
};

export const updateOpexLocation = async (formData) => {
	const response = await axiosUploadFormData(`${prefixV2}update-opex`, formData);
	return response;
};

export const updateSOCCode = async (formData) => {
	const response = await axiosUploadFormData(`${prefixV2}update-soc-codes`, formData);
	return response;
};

export const shareDashboard = async (data) => {
	const response = await axiosPost(`/api/v2/share-dashboard/send-invitation`, data);
	return response;
};

export const exchangeInvitationToken = async (data) => {
	const response = await axiosPost(`/api/v2/share-dashboard/send-magic-link`, data);
	return response;
};
export const loginMagicLink = async (data) => {
	const response = await axiosPost(`/api/v2/share-dashboard/login-magic-link`, data);
	return response;
};

export const generateSIDashboard = async (data) => {
	return await axiosPost(`${prefixV2}generate-si-dashboard`, data);
};

export const geocodeAutocomplete = async (data) => {
	return await axiosGet(`${import.meta.env.VITE_REACT_APP_API_URL + "/api/v2/"}geocoding-autocomplete`, data);
};

const streamUrl = import.meta.env.VITE_REACT_APP_API_URL + "/api/v1/score-insights/user-dashboards-status";

const pollInterval = 5000; // Time interval between each poll request in milliseconds

export const processPolling = (dispatch, onMessage, getState) => {
	let shouldContinue = true;

	const poll = async () => {
		const state = getState();
		const token = state.token;

		if (!token) {
			console.error("Token is missing");
			return;
		}

		try {
			const response = await fetch(`${streamUrl}`, {
				headers: {
					"Authorization": `Bearer ${token}`,
					"Accept": "application/json",
				},
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const data = await response.json();

			if (data === "close") {
				console.log("Connection closed");
				shouldContinue = false;
			} else {
				dispatch({ type: GET_DASHBOARDS, payload: data });
				onMessage(data);
			}
		} catch (error) {
			console.log("Polling error: ", error);
		} finally {
			if (shouldContinue) {
				setTimeout(poll, pollInterval);
			}
		}
	};

	poll();

	return () => {
		console.log("Stopping polling");
		shouldContinue = false;
	};
};

// This will get updated avgerage drive times
// based on which employees/customers are active
export const getUpdatedDriveTimes = async (data) => {
	const response = await axiosPost(`/api/v2/business-insights/update-drive-times`, data);
	return response;
};
