import React, { useState, useRef, useEffect, useCallback } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import InputText from "../../../../../components/InputText/InputText";
import InputToggle from "../../../../../components/InputToggle/InputToggle";
import InputCheckbox from "../../../../../components/InputCheckbox/InputCheckbox";
import Icon from "../../../../../components/Icon/Icon";
import CleanModal from "../../../../../components/modal/CleanModal";
import { removeLocationPDFPhotos } from "../../../../../services/dashboards/townson-dashboard.services/townson-dashboard-data";
import "./PdfImages.scss";
import { updatePhotos } from "../../../../../services/dashboards/townson-dashboard.services/townson-dashboard-data";
import { debounce } from "lodash";
const PdfImages = ({ index, images, photos, setPhotos, locationId }) => {
	const cardWidth = 180;
	const margin = 20;
	const [isDragging, setIsDragging] = useState(false);
	const [isInitialized, setIsInitialized] = useState(false);
	const containerRefs = useRef({});
	const cardRefs = useRef({});
	const [photosForDeletion, setPhotosForDeletion] = useState([]);
	const [trigger, setTrigger] = useState(false);

	// Get photos for current location
	const locationPhotos = photos[index] || {};

	const updatePhotoOrder = useCallback(
		async (pdfKey, orderedPhotos) => {
			try {
				const payload = {
					photos: {
						locationId: locationId,
						pdfName: pdfKey,
						images: orderedPhotos.map((photo, index) => ({
							url: photo.url,
							order: index,
							primary: photo.primary,
							status: photo.status,
							name: photo.name,
						})),
					},
				};

				await updatePhotos(payload);
			} catch (error) {
				console.error("Error updating photo order:", error);
			}
		},
		[locationId],
	);

	const debouncedUpdatePhotos = useCallback(debounce(updatePhotoOrder, 200), [updatePhotoOrder]);

	useEffect(() => {
		return () => {
			debouncedUpdatePhotos.cancel();
		};
	}, [debouncedUpdatePhotos]);

	const handleNameChange = (value, pdfKey, photoIndex) => {
		setPhotos((prevPhotos) => {
			const newPhotos = { ...prevPhotos };
			const locationPhotosCopy = { ...newPhotos[index] };

			locationPhotosCopy[pdfKey] = locationPhotosCopy[pdfKey].map((photo, idx) =>
				idx === photoIndex ? { ...photo, name: value } : photo,
			);

			newPhotos[index] = locationPhotosCopy;

			debouncedUpdatePhotos(pdfKey, locationPhotosCopy[pdfKey]);

			return newPhotos;
		});
	};

	const handleStatusChange = (event, pdfKey, photoIndex) => {
		setPhotos((prevPhotos) => {
			const newPhotos = { ...prevPhotos };
			const locationPhotosCopy = { ...newPhotos[index] };

			locationPhotosCopy[pdfKey] = locationPhotosCopy[pdfKey].map((photo, idx) =>
				idx === photoIndex ? { ...photo, status: event.target.checked } : photo,
			);

			newPhotos[index] = locationPhotosCopy;

			debouncedUpdatePhotos.cancel();
			updatePhotoOrder(pdfKey, locationPhotosCopy[pdfKey]);

			return newPhotos;
		});
	};

	const handlePrimaryChange = (event, pdfKey, photoIndex) => {
		setPhotos((prevPhotos) => {
			const newPhotos = { ...prevPhotos };
			const locationPhotosCopy = { ...newPhotos[index] };

			Object.keys(locationPhotosCopy).forEach((key) => {
				if (Array.isArray(locationPhotosCopy[key])) {
					locationPhotosCopy[key] = locationPhotosCopy[key].map((photo) => ({
						...photo,
						primary: false,
					}));
				}
			});

			if (locationPhotosCopy[pdfKey]) {
				locationPhotosCopy[pdfKey] = locationPhotosCopy[pdfKey].map((photo, idx) => {
					if (idx === photoIndex) {
						return {
							...photo,
							primary: event.target.checked,
							status: event.target.checked ? true : photo.status,
						};
					}
					return photo;
				});
			}

			newPhotos[index] = locationPhotosCopy;

			debouncedUpdatePhotos.cancel();
			updatePhotoOrder(pdfKey, locationPhotosCopy[pdfKey]);

			return newPhotos;
		});
	};

	const handleRemoveChange = (checked, pdfKey, photoIndex) => {
		setPhotos((prevPhotos) => {
			const newPhotos = { ...prevPhotos };
			const locationPhotosCopy = { ...newPhotos[index] };

			locationPhotosCopy[pdfKey] = locationPhotosCopy[pdfKey].map((photo, idx) =>
				idx === photoIndex ? { ...photo, remove: checked } : photo,
			);

			newPhotos[index] = locationPhotosCopy;
			return newPhotos;
		});
	};

	const handleTrashClick = (pdfKey) => {
		if (!locationPhotos[pdfKey]) return;
		const photosToRemove = locationPhotos[pdfKey].filter((photo) => photo.remove);
		setPhotosForDeletion(photosToRemove);
	};

	const handleDeletePhotos = async () => {
		const res = await removeLocationPDFPhotos(photosForDeletion);

		if (res?.code === 200) {
			setPhotos((prevPhotos) => {
				const newPhotos = { ...prevPhotos };
				const locationPhotosCopy = { ...newPhotos[index] };

				const photosByPdf = photosForDeletion.reduce((acc, photo) => {
					if (!acc[photo.pdfKey]) {
						acc[photo.pdfKey] = [];
					}
					acc[photo.pdfKey].push(photo.url);
					return acc;
				}, {});

				Object.keys(photosByPdf).forEach((pdfKey) => {
					if (locationPhotosCopy[pdfKey]) {
						locationPhotosCopy[pdfKey] = locationPhotosCopy[pdfKey].filter(
							(photo) => !photosByPdf[pdfKey].includes(photo.url),
						);
					}
				});

				newPhotos[index] = locationPhotosCopy;
				return newPhotos;
			});

			setPhotosForDeletion([]);
			setTrigger(false);
		}
	};

	useEffect(() => {
		if (!isInitialized && images?.length > 0) {
			const photosByPdf = {};
			images.forEach((pdfData) => {
				const pdfKey = pdfData.pdf_name;
				const pdfImages = pdfData.extracted_images.map((image, idx) => ({
					...image,
					id: `${pdfKey}-${idx}`,
					location_id: locationId,
					pdfKey,
					originalIndex: idx,
					url: image.url,
					name: image.name || "",
					status: image.status ?? false,
					primary: image.primary ?? false,
					order: idx,
					remove: false,
				}));
				photosByPdf[pdfKey] = pdfImages;
			});

			setPhotos((prevPhotos) => ({
				...prevPhotos,
				[index]: photosByPdf,
			}));

			setIsInitialized(true);
		}
	}, [images, setPhotos, isInitialized, locationId, index]);

	const handleDragStart = () => {
		setIsDragging(true);
		Object.values(containerRefs.current).forEach((ref) => {
			if (ref) {
				ref.style.scrollBehavior = "auto";
				ref.style.scrollSnapType = "none";
			}
		});
	};

	const handleDragEnd = (result) => {
		setIsDragging(false);
		if (!result.destination) return;

		const pdfKey = result.destination.droppableId.replace("droppable-", "");

		setPhotos((prevPhotos) => {
			const newPhotos = { ...prevPhotos };
			const locationPhotosCopy = { ...newPhotos[index] };
			const items = Array.from(locationPhotosCopy[pdfKey] || []);

			const [reorderedItem] = items.splice(result.source.index, 1);
			items.splice(result.destination.index, 0, reorderedItem);

			items.forEach((item, i) => {
				item.order = i;
			});

			locationPhotosCopy[pdfKey] = items;
			newPhotos[index] = locationPhotosCopy;

			debouncedUpdatePhotos.cancel();
			updatePhotoOrder(pdfKey, items);

			return newPhotos;
		});

		const cardId = `${pdfKey}-${result.destination.index}`;
		setTimeout(() => {
			const cardElement = cardRefs.current[cardId];
			if (cardElement) {
				cardElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
			}
		}, 0);
	};

	const hasAnyRemoved = Object.values(locationPhotos).some(
		(photoArray) => Array.isArray(photoArray) && photoArray.some((photo) => photo.remove),
	);

	const getSelectedCount = (pdfPhotos) => {
		return pdfPhotos.filter((photo) => photo.remove).length;
	};

	if (!images?.length) return null;

	const renderRows = images.map((pdfData) => {
		const pdfKey = pdfData.pdf_name;
		const pdfPhotos = locationPhotos[pdfKey] || [];
		const selectedCount = getSelectedCount(pdfPhotos);

		if (pdfPhotos.length === 0) return null;

		return (
			<div key={pdfKey} className='pdf-row mb-4'>
				<h6 className='mb-3 d-flex'>
					<div>
						{pdfData.pdf_name} (
						{selectedCount > 0 ? `${selectedCount} photo selected to remove` : `${pdfPhotos.length} photos`}
						)
					</div>
					<div
						style={{ opacity: `${hasAnyRemoved ? 1 : 0}` }}
						className='d-flex ml-auto mr-0'
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleTrashClick(pdfKey);
							setTrigger(true);
						}}
					>
						<Icon
							theme='primary'
							className='trash ml-auto mr-0 border-0 pointer svg-fill--primary'
							size='md'
							icon='delete-bin-line'
						/>
					</div>
				</h6>
				<DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
					<Droppable droppableId={`droppable-${pdfKey}`} direction='horizontal'>
						{(provided) => (
							<div
								className='draggable-container'
								ref={(el) => {
									provided.innerRef(el);
									containerRefs.current[pdfKey] = el;
								}}
								{...provided.droppableProps}
								style={{
									display: "flex",
									width: "100%",
									overflowX: "auto",
									padding: "8px 0 32px",
									scrollBehavior: isDragging ? "auto" : "smooth",
									scrollSnapType: isDragging ? "none" : "x mandatory",
									position: "relative",
								}}
							>
								{pdfPhotos.map((item, photoIndex) => (
									<Draggable
										className='draggable-card-item'
										key={item.id}
										draggableId={item.id}
										index={photoIndex}
									>
										{(provided, snapshot) => {
											const draggableStyle = {
												...provided.draggableProps.style,
												width: `${cardWidth}px`,
												flexShrink: 0,
												padding: "10px",
												userSelect: "none",
												marginRight: `${margin}px`,
												scrollSnapAlign: "start",
											};

											if (!snapshot.isDragging) {
												draggableStyle.transform = draggableStyle.transform || "none";
											}

											return (
												<div
													ref={(el) => {
														provided.innerRef(el);
														cardRefs.current[`${pdfKey}-${photoIndex}`] = el;
													}}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													className={`${
														item.remove ? "to-remove" : ""
													} position-relative image-card ${
														snapshot.isDragging ? "dragging-item" : ""
													}`}
													style={draggableStyle}
												>
													<div className={`checkbox ${hasAnyRemoved ? "d-block" : ""}`}>
														<InputCheckbox
															className={`border-0 ${hasAnyRemoved ? "d-block" : ""}`}
															isChecked={item.remove}
															onValueChange={(checked) =>
																handleRemoveChange(checked, pdfKey, photoIndex)
															}
														/>
													</div>
													<div>
														<div
															style={{
																width: "100%",
																height: "160px",
																margin: "auto",
															}}
															className='position-relative overflow-hidden image'
														>
															<img
																className='position-absolute'
																src={item.url}
																alt={`${pdfKey} Image ${photoIndex + 1}`}
																style={{
																	width: "100%",
																	height: "90%",
																	top: 0,
																	left: 0,
																	objectFit: "cover",
																	pointerEvents: "none",
																}}
															/>
														</div>
														<InputText
															labelClass='text-primary'
															label='Name'
															disabled={item.remove}
															type='text'
															value={item.name}
															onValueChange={(value) =>
																handleNameChange(value, pdfKey, photoIndex)
															}
														/>
														<InputToggle
															className='text-dark fw-normal mt-2'
															trueColor='#5c86c1'
															disabled={item.remove}
															label='Primary'
															isChecked={item.primary}
															onValueChange={(e) =>
																handlePrimaryChange(e, pdfKey, photoIndex)
															}
														/>
														{!item.primary && (
															<InputToggle
																className='text-dark fw-normal'
																trueColor='#5c86c1'
																disabled={item.remove}
																label={"Additional"}
																isChecked={item.status}
																onValueChange={(e) =>
																	handleStatusChange(e, pdfKey, photoIndex)
																}
															/>
														)}
													</div>
												</div>
											);
										}}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
		);
	});

	return (
		<>
			<CleanModal
				title='Confirm Delete Photos'
				className='sm'
				trigger={trigger}
				setTrigger={setTrigger}
				customButton={[
					<div key='save-div' className='justify-center d-flex'>
						<button
							onClick={(e) => {
								e.preventDefault();
								setPhotosForDeletion([]);
								setTrigger(false);
							}}
							style={{ marginRight: "24px" }}
							className='btn secondary outline'
						>
							Cancel
						</button>
						<button
							key='save'
							className='btn primary'
							onClick={(e) => {
								e.preventDefault();
								handleDeletePhotos();
							}}
						>
							Delete
						</button>
					</div>,
				]}
			>
				<div>
					<p className='text-center'>
						Are you sure you want to delete the{" "}
						{photosForDeletion.length > 1 ? photosForDeletion.length : ""} selected{" "}
						{photosForDeletion.length > 1 ? "photos" : "photo"}?
					</p>
				</div>
			</CleanModal>
			<div className='pdf-pictures-container'>{renderRows.filter((row) => row !== null)}</div>
		</>
	);
};

export default PdfImages;
