import {
	GET_DASHBOARD,
	RESET_DASHBOARD,
	SET_LOADING,
	SHOW_HIDE_CUSTOMER_GROUP_LOCATION,
	SHOW_HIDE_CUSTOMER_MOSAIC_LOCATION,
	SHOW_HIDE_EMPLOYEE_GROUP_LOCATION,
	SHOW_HIDE_EMPLOYEE_MOSAIC_LOCATION,
} from "./locationTypes";

const initialState = {
	location: "",
	dashboard: "",
	id: "",
	loading: false,
};

const locationReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_DASHBOARD:
			return {
				...state,
				location: action.payload,
				id: action.id,
				employeeTitleCounts: action.employeeTitleCounts,
				customerTitleCounts: action.customerTitleCounts,
				loading: false,
			};
		case SHOW_HIDE_EMPLOYEE_GROUP_LOCATION:
			return {
				...state,
				employeeTitleCounts: [...action.payload],
				loading: false,
			};
		case SHOW_HIDE_CUSTOMER_GROUP_LOCATION:
			return {
				...state,
				customerTitleCounts: [...action.payload],
				loading: false,
			};
		case SHOW_HIDE_EMPLOYEE_MOSAIC_LOCATION:
			return {
				...state,
				hiddenEmployeeMosaics: [...action.payload],
				loading: false,
			};
		case SHOW_HIDE_CUSTOMER_MOSAIC_LOCATION:
			return {
				...state,
				hiddenCustomerMosaics: [...action.payload],
				loading: false,
			};

		case SET_LOADING:
			return {
				...state,
				loading: true,
				id: action.id,
			};
		case RESET_DASHBOARD:
			return {
				...state,
				location: "",
				id: "",
			};

		case "CLEAR_LOCATION":
			return {
				...state,
				location: {},
			};
		default:
			return state;
	}
};

export default locationReducer;
