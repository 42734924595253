// components/Map/POIMarker.jsx

import "./LocationMarker.styles.scss";

import React, { useEffect, useRef, useState } from "react";
import { Marker, Popup } from "react-map-gl";

import { formatMoney } from "../../helper/number.helpers";

const LocationMarker = ({ location, label, current, focusedLocation, activeLocation }) => {
	// MARKER
	const markerRef = useRef();

	/** Build the class list for the marker */
	const getMarkerClasses = () => {
		const classes = ["location-marker"];

		// Is Current Location
		current && classes.push("current");

		// Active Location
		if (activeLocation === location._id) {
			classes.push("active");
		}

		// Emphasize/Understate class for focused Marker
		if (focusedLocation !== null) {
			if (location._id === focusedLocation) {
				classes.push("emphasize");
			} else {
				classes.push("understate");
			}
		}

		return classes.toString().replace(/,/g, " ");
	};

	// POPUP
	const popupRef = useRef();
	const [popupOpen, setPopupOpen] = useState(false);

	const handleClickOutside = (event) => {
		if (popupRef.current && !popupRef.current.contains(event.target)) {
			setPopupOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, []);

	return (
		<>
			{popupOpen && (
				<>
					<Popup
						className={"location-marker-popup"}
						latitude={location.latitude}
						longitude={location.longitude}
						offset={{ bottom: [0, -40] }}
						maxWidth={500}
						onClose={() => setPopupOpen(false)}
					>
						<div ref={popupRef} className='popup-head'>
							<h6>
								<span className={"location-label"}>{label}</span> Proposed Location
							</h6>
							<p className={"address"}>{location.location_name}</p>
							<p className={"leasing-company"}>Leasing Company: {location.leasing_company}</p>
						</div>
						<div className='popup-body'>
							<div className='price-status-wrapper'>
								<h6>
									{formatMoney(location.gross_price_sf || 0)} <span>SF (Rent)</span>
								</h6>
								<h6>
									$ 7.00 <span>SF (OPEX)</span>
								</h6>
								<h6>
									$ 19.50 <span>Gross</span>
								</h6>
							</div>
							<div className='notes-container'>
								<h6 className='notes-title'>Notes</h6>
								<p className='notes'>Great access, bad egress</p>
							</div>
						</div>
					</Popup>
				</>
			)}

			<Marker
				latitude={location.latitude}
				longitude={location.longitude}
				anchor='bottom'
				onClick={(e) => {
					e.originalEvent.stopPropagation();
					setPopupOpen(!popupOpen);
				}}
			>
				<div ref={markerRef} className={getMarkerClasses()}>
					{label && <div className={"marker-label"}>{label}</div>}
					{!label && (
						<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 16C13.0609 16 14.0783 15.5786 14.8284 14.8284C15.5786 14.0783 16 13.0609 16 12C16 10.9391 15.5786 9.92172 14.8284 9.17157C14.0783 8.42143 13.0609 8 12 8C10.9391 8 9.92172 8.42143 9.17157 9.17157C8.42143 9.92172 8 10.9391 8 12C8 13.0609 8.42143 14.0783 9.17157 14.8284C9.92172 15.5786 10.9391 16 12 16V16ZM12 18C10.4087 18 8.88258 17.3679 7.75736 16.2426C6.63214 15.1174 6 13.5913 6 12C6 10.4087 6.63214 8.88258 7.75736 7.75736C8.88258 6.63214 10.4087 6 12 6C13.5913 6 15.1174 6.63214 16.2426 7.75736C17.3679 8.88258 18 10.4087 18 12C18 13.5913 17.3679 15.1174 16.2426 16.2426C15.1174 17.3679 13.5913 18 12 18ZM12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14Z'
								fill='white'
							></path>
						</svg>
					)}
				</div>
			</Marker>
		</>
	);
};

export default LocationMarker;
