import React from "react";
import { Link } from "react-router-dom";
function ExcelTemplate({ officeLocationType }) {
	return (
		<div className='text-center'>
			{!officeLocationType ? (
				<Link to='/assets/BI-Office-Template-Example.xlsx' target='_blank' download>
					Click to Download Office Template
				</Link>
			) : (
				<Link to='/assets/BI-Industrial-Template-Example.xlsx' target='_blank' download>
					Click to Download Industrial Template
				</Link>
			)}
		</div>
	);
}

export default ExcelTemplate;
