import React from "react";
import { tableFootnoteContent, renderLocationInfo, getMetricValue } from "./ComparisonViewExcel.helpers";
import { useComparison } from "./ComparisonContext";
import EditableCell from "./ComparisonViewEditableCell";
function ComparisonViewTable({ className, visibleMetrics, visibleLocations, photos }) {
	const {
		comparisonData: contextData,
		setComparisonData,
		metricEdits,
		saveMetricEdits,
		cancelMetricEdits,
	} = useComparison();

	return (
		<div className={`table-container ${className}`}>
			<h4>{contextData?.dashboard_title ?? ""}</h4>
			<div className='d-flex justify-content-between align-items-center' style={{ padding: "16px 0" }}>
				{contextData.dashboardlogo ? (
					<img width={90} className='logo' src={contextData.dashboardlogo} alt='' />
				) : (
					""
				)}
			</div>
			<div className='table-wrapper'>
				<table>
					<thead>
						<tr className='position-relative'>
							<th className='location-header location-count text-center' style={{ width: "50px" }}>
								#
							</th>
							<th className='location-header location_photo'>Photo</th>
							<th className='location-header'>Location</th>
							{visibleMetrics.map((metric, index) => {
								const metricKey = Object.keys(metric)[0];
								const metricName = metric[metricKey];
								const sourceKey = metric.source_key;

								return (
									<th key={index} className={`${metricKey} metric-header position-relative`}>
										<div className='align-items-center'>
											<span className='ml-2'>
												{metricKey === "leasing_company" ? (
													<>
														{metricName.split("/").map((part, i) =>
															i === 0 ? (
																part
															) : (
																<>
																	<br />
																	{part}
																</>
															),
														)}
													</>
												) : (
													<>
														{metricName}
														{sourceKey && <sup>{sourceKey}</sup>}
													</>
												)}
											</span>
										</div>
									</th>
								);
							})}
						</tr>
					</thead>
					<tbody>
						{visibleLocations.map((location, locationIndex) => (
							<tr key={location._id} className={`${locationIndex % 2 === 0 ? "even" : "odd"}`}>
								<td className='text-center position-relative number-cell'>
									<span>{locationIndex + 1}</span>
								</td>
								<td className='location-column'>{renderLocationInfo(location, photos)}</td>
								<td className='location-name-cell'>
									<span className='location-name'>
										{location.location_name}
										{location.is_current ? "*" : ""}
									</span>
								</td>
								{visibleMetrics.map((metric, metricIndex) => {
									const metricKey = Object.keys(metric)[0];
									const isEditable = metric.editable;
									return (
										<td key={metricIndex} className={`metric-value ${metricKey}`}>
											<EditableCell
												location={location}
												metricKey={metricKey}
												currentValue={getMetricValue(location, metricKey)}
												isEditable={isEditable}
												commas={metricKey !== "year_built"}
												suffix={metricKey === "clear_height" ? "ft" : ""}
											/>
										</td>
									);
								})}
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<div dangerouslySetInnerHTML={{ __html: tableFootnoteContent(contextData?.socData?.soc_name) }} />
		</div>
	);
}

export default ComparisonViewTable;
