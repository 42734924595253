import React from "react";

function AddSocCode({ socCodeList, selectedSocCode, handleToggleCheckboxSocCode, errorSOCValidation }) {
	// Map selectedSocCode to an array of ids
	const selectedSocCodeIds = selectedSocCode.map((soc) => soc.id);

	const recursiveFunctionForRender = (codeList) => {
		return (
			codeList &&
			codeList.map((code, index) => (
				<div className='soc-code-list-item' key={index}>
					<label className='soc-code-list-title body-2'>
						{code.soc_code} {code.soc_name}
					</label>
					<input
						type='checkbox'
						className='accordion-check'
						checked={selectedSocCodeIds.includes(code.id)}
						onChange={() => {
							handleToggleCheckboxSocCode(code);
						}}
						name={code.soc_name}
						id={code.id}
					/>
				</div>
			))
		);
	};

	const filterFunction = (e) => {
		let filter;
		let li;
		let i;
		filter = e.target.value.toUpperCase();
		const div = document.getElementById(`soc-code-list-items`);
		li = div.getElementsByTagName("label");
		for (i = 0; i < li.length; i++) {
			const txtValue = li[i].textContent || li[i].innerText;
			if (txtValue.toUpperCase().indexOf(filter) > -1) {
				li[i].parentElement.style.display = "";
			} else {
				li[i].parentElement.style.display = "none";
			}
		}
	};

	const handleFocus = (e) => {
		e.target.classList.add("hasFocus");
	};

	const handleBlur = (e) => {
		if (e.target.value === "") {
			e.target.classList.remove("hasFocus");
		}
	};

	return (
		<div className='db-state-table-modal-step-item step-three'>
			<div className='soc-code-wrapper'>
				<div className='soc-code-list-item-title'>
					<p>Labor Profiles (SOC)</p>
				</div>
				<div className='select-search-input-wrap'>
					{errorSOCValidation && <span style={{ color: "red" }}>{errorSOCValidation}</span>}
					<div className='input-item'>
						<input
							type='text'
							id='search-soc'
							className='select-search-input'
							onKeyUp={(e) => filterFunction(e)}
							onFocus={handleFocus}
							onBlur={handleBlur}
							onClick={handleFocus}
							onChange={() => {}}
						/>
						<label htmlFor=''>Search Labor</label>
					</div>
				</div>
				<div className='soc-code-list-items' id='soc-code-list-items'>
					{recursiveFunctionForRender(socCodeList)}
				</div>
			</div>
		</div>
	);
}

export default AddSocCode;
