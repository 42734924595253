// components/InputText/InputText.jsx

import "./InputText.style.scss";

import React, { useState } from "react";

import { randomString } from "../../helper/string.helpers";
import Icon from "../Icon/Icon";
import InputWrapper from "../InputWrapper/InputWrapper";

/**
 * @typedef {Object} InputTextProps
 * @property {string | null} [id] - The ID for the input
 * @property {string | null} [className] - Additional Class(s) to add to the Component.
 * @property {string} [type] - The input `type`.
 * @property {string} [label] - The label to appear above the input
 * @property {boolean} [srOnlyLabel] -
 * @property {boolean} [inline] -
 * @property {string} [moreInfo] -
 * @property {string} [footnote] -
 * @property {"md" | "sm"} [size] -
 * @property {"default" | "gray-outline" | null} [theme] -
 * @property {boolean} [required] -
 * @property {boolean} [invalid] -
 * @property {string} [invalidMessage] -
 * @property {string} [prefixIcon] -
 * @property {string} [suffixIcon] -
 * @property {string} [placeholder] -
 * @property {boolean} [autocomplete] -
 * @property {boolean} [readonly] -
 * @property {boolean} [disabled] -
 * @property {string | number} value -
 * @property {React.Dispatch<React.SetStateAction<InputTextProps["value"]>>} onValueChange -
 */

/**
 * An InputText Component.
 * @param {InputTextProps} props
 * @return {JSX.Element}
 * @constructor
 */
/** @type InputTextProps */

const InputText = (props) => {
	const {
		onKeyDown = () => false,
		id = randomString(),
		className = "",
		type = "text",
		label = "",
		srOnlyLabel = false,
		inline = false,
		moreInfo = "",
		footnote = "",
		size = "md",
		theme = "default",
		required = false,
		invalid = false,
		invalidMessage = "This is required",
		prefixIcon = "",
		suffixIcon = "",
		placeholder = "",
		autocomplete = false,
		readonly = false,
		disabled = false,
		value = "",
		min = "",
		max = "",
		onValueChange = (v) => v,
		onBlur = () => {},
		onFocus = () => {},
	} = props;

	const getClasses = () => {
		const classes = ["input-text"];
		className && classes.push(className);
		theme && classes.push(`theme-${theme}`);
		size && classes.push(`size-${size}`);
		return classes.join(" ");
	};

	const handleChange = (e) => {
		const { value } = e.target;
		onValueChange(value);
	};

	return (
		<InputWrapper
			id={id}
			labelClass={props.labelClass}
			theme={theme}
			label={label}
			srOnlyLabel={srOnlyLabel}
			required={required}
			moreInfo={moreInfo}
			footnote={footnote}
			inline={inline}
			invalid={invalid}
			invalidMessage={invalidMessage}
		>
			<div className={getClasses()}>
				<input
					type={type}
					id={id}
					className={`${invalid ? "is-invalid" : ""} ${prefixIcon ? "prefix" : ""} ${
						suffixIcon ? "suffix" : ""
					}`}
					onKeyDown={onKeyDown}
					placeholder={placeholder}
					readOnly={readonly}
					disabled={disabled}
					autoComplete={autocomplete ? "on" : "off"}
					inputMode='text'
					value={value || ""}
					onChange={handleChange}
					min={min}
					max={max}
					onBlur={onBlur}
					onFocus={onFocus}
				/>

				{prefixIcon && (
					<div className='input-prefix-icon'>
						<Icon icon={prefixIcon} size={size} />
					</div>
				)}

				{suffixIcon && (
					<div className='input-suffix-icon'>
						<Icon icon={suffixIcon} size={size} />
					</div>
				)}
			</div>
		</InputWrapper>
	);
};

export default InputText;
