import { brandColors, hueRotate } from "../../functions/brandColors.array";
import { getDashboardDetails } from "../../services/dashboards/townson-dashboard.services/townson-dashboard-data";
import {
	DASHBOARD_ERROR,
	GET_DASHBOARD,
	RESET_DASHBOARD,
	SET_LOADING,
	SHOW_HIDE_CUSTOMER_GROUP_LOCATION,
	SHOW_HIDE_CUSTOMER_MOSAIC_LOCATION,
	SHOW_HIDE_EMPLOYEE_GROUP_LOCATION,
	SHOW_HIDE_EMPLOYEE_MOSAIC_LOCATION,
} from "./locationTypes";

export const employeeFallbackTitle = "Default Group";
export const customerFallbackTitle = employeeFallbackTitle;

export const getDashboard = (x, y) => async (dispatch) => {
	let employeeTitleCounts;
	let customerTitleCounts;
	try {
		dispatch(setLoading(x));
		const res = await getDashboardDetails({ id: x, filter_active_images: y });
		const data = await res.data;
		if (data.townsonEmployee.length !== 0) {
			const employeeTitles = data.townsonEmployee.map((employee) =>
				employee.job_title.trim() === "" ? employeeFallbackTitle : employee.job_title,
			);

			const titleCount = employeeTitles.reduce((counts, title) => {
				counts[title] = counts[title] ? counts[title] + 1 : 1;
				return counts;
			}, {});

			employeeTitleCounts = Object.entries(titleCount).map(([title, count]) => ({ jobTitle: title, count }));

			employeeTitleCounts = employeeTitleCounts.map((arrayItem, index) => {
				const item = {
					...arrayItem,
					color: hueRotate(brandColors[index % brandColors.length], index * 3.5),
					visible: true,
					baseColor: { color: brandColors[index % brandColors.length], shift: index * 3.5 },
				};

				return item;
			});
		}

		if (data.customers && data.customers.length !== 0) {
			const customerTitles = data.customers.map((customer) =>
				customer.group_title.trim() === "" ? customerFallbackTitle : customer.group_title,
			);

			const customerTitleCount = customerTitles.reduce((counts, title) => {
				counts[title] = counts[title] ? counts[title] + 1 : 1;
				return counts;
			}, {});

			customerTitleCounts = Object.entries(customerTitleCount).map(([title, count]) => ({
				groupTitle: title,
				count,
			}));

			customerTitleCounts = customerTitleCounts.map((arrayItem, index) => {
				return {
					...arrayItem,
					color: hueRotate(brandColors[index % brandColors.length], index * 3.5),
					visible: true,
					baseColor: { color: brandColors[index % brandColors.length], shift: index * 3.5 },
				};
			});
		}

		dispatch({
			type: GET_DASHBOARD,
			payload: data,
			id: x,
			employeeTitleCounts: employeeTitleCounts ? employeeTitleCounts : "none",
			customerTitleCounts: customerTitleCounts ? customerTitleCounts : "none",
		});
	} catch (err) {
		dispatch({
			type: DASHBOARD_ERROR,
			payload: err.response,
		});
	}
};

export const showHideEmployeeGroups = (employees) => {
	return (dispatch) => {
		// console.log("showHideEmployeeGroups from actions", employees);
		dispatch({
			type: SHOW_HIDE_EMPLOYEE_GROUP_LOCATION,
			payload: employees,
		});
	};
};

export const showHideCustomerGroups = (customers) => {
	return (dispatch) => {
		dispatch({
			type: SHOW_HIDE_CUSTOMER_GROUP_LOCATION,
			payload: customers,
		});
	};
};

export const showHideEmployeeMosaics = (employees) => {
	return (dispatch) => {
		dispatch({
			type: SHOW_HIDE_EMPLOYEE_MOSAIC_LOCATION,
			payload: employees,
		});
	};
};

export const showHideCustomerMosaics = (customers) => {
	return (dispatch) => {
		dispatch({
			type: SHOW_HIDE_CUSTOMER_MOSAIC_LOCATION,
			payload: customers,
		});
	};
};

export const setLoading = (x) => {
	return {
		type: SET_LOADING,
		id: x,
	};
};

export const resetHandler = () => {
	return {
		type: RESET_DASHBOARD,
	};
};
