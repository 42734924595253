import React from "react";
import { useEffect } from "react";
/**
 * A clean, reusable modal component with customizable content and behavior.
 *
 * @component
 
 * @param {string} [props.className=""] - Additional CSS classes to apply to the modal
 * @param {boolean} [props.trigger=false] - Controls the visibility of the modal
 * @param {Function} props.setTrigger - Callback function to update the trigger state
 * @param {string} [props.title="Modal Title"] - The title text displayed in the modal header
 * @param {React.ReactNode} props.children - The content to be rendered inside the modal body
 * @param {React.ReactNode|boolean} [props.customButton=false] - Custom button/content for the modal footer
 * @param {boolean} [props.hideX] - When true, hides the close (X) button in the header
 *
 * @example
 * // Basic usage
 * <CleanModal
 *   trigger={isModalOpen}
 *   setTrigger={setIsModalOpen}
 *   title="My Modal"
 * >
 *   <p>Modal content goes here</p>
 * </CleanModal>
 *
 * @example
 * // With custom button and additional className
 * <CleanModal
 *   trigger={isModalOpen}
 *   setTrigger={setIsModalOpen}
 *   title="Custom Modal"
 *   className="custom-modal"
 *   customButton={<button onClick={handleSave}>Save Changes</button>}
 * >
 *   <p>Modal content with custom footer button</p>
 * </CleanModal>
 *
 * @returns {JSX.Element} The rendered modal component
 */
const CleanModal = ({
	className = "",
	trigger = false,
	setTrigger,
	title = "Modal Title",
	children,
	customButton = false,
	hideX,
	removeFooterBorder = false,
}) => {
	useEffect(() => {
		if (trigger) {
			const handleEscape = (event) => {
				if (event.key === "Escape") {
					setTrigger(false);
				}
			};

			document.addEventListener("keydown", handleEscape);

			return () => {
				document.removeEventListener("keydown", handleEscape);
			};
		}
	}, [trigger, setTrigger]);

	return (
		<>
			<div className={`client-data-upload overlay ${trigger ? "show" : ""}`} onClick={() => {}}></div>

			<div className={`db-state-table-modal position-fixed c-modal ${className} ${trigger ? "show" : ""}`}>
				<div className='c-modal-inner'>
					<div className='card'>
						<div className='card-body'>
							<div className='card-title modal-header'>
								<label htmlFor=''>{title}</label>
								{!hideX && (
									<div className='close-btn' onClick={() => setTrigger(false)}>
										<svg width='14' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'>
											<path
												d='M13 1.00195L1 13.002'
												stroke='#111128'
												strokeWidth='2'
												strokeLinecap='round'
												strokeLinejoin='round'
											></path>
											<path
												d='M1 1.00195L13 13.002'
												stroke='#111128'
												strokeWidth='2'
												strokeLinecap='round'
												strokeLinejoin='round'
											></path>
										</svg>
									</div>
								)}
							</div>
							<div className='card-content modal-body'>
								<div className='db-state-table-modal-steps'>{children}</div>
							</div>
							{customButton && (
								<div className={`modal-footer ${removeFooterBorder ? "border-0" : ""}`}>
									<div className='row m-0'>
										<div className='col col-12 save-div'>{customButton}</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CleanModal;
