// InputWrapper.jsx

import "./InputWrapper.style.scss";

import React from "react";

import Icon from "../Icon/Icon";
import InputFootnote from "../InputFootnote/InputFootnote";
import InputInvalidMessage from "../InputInvalidMessage/InputInvalidMessage";
import InputLabel from "../InputLabel/InputLabel";
import Tooltip from "../tooltip/tooltip";
/**
 * Props for a InputWrapper component.
 * @typedef {Object} InputWrapperProps
 * @property {string | null} [id]  - The ID of the component.
 * @property {string | null} [label] - the label that appears to the left of the toggle.
 * @property {boolean} [required] - Add an asterisk to the label.
 * @property {string | null} [moreInfo] - Adds a tooltip to the end of the label.
 * @property {boolean} [srOnlyLabel] - hides the label in the browser.
 * @property {"light" | "dark"} [theme] - changes the `theme` of the toggle for light and dark mode.
 * @property {string | null} [footnote] - the footnote that appears under the wrapped input.
 * @property {string | null} [invalidMessage] - the invalidMessage that appears under the wrapped input.
 */

/**
 * A InputWrapper Component.  Used to wrap a form input to give it a label, invalid message, footnote, and appropriate margins/padding.
 * @param {InputWrapperProps} props
 * @return {JSX.Element}
 * @constructor
 */
const InputWrapper = (props) => {
	const getClasses = () => {
		const classes = ["input-wrapper"];

		// THEME
		props.theme && classes.push(`theme-${props.theme}`);

		// INVALID
		props.invalid && classes.push("is-invalid");

		props.className && classes.push(props.className);

		// INLINE
		props.inline && classes.push("inline");

		return classes.toString().replace(/,/g, " ");
	};

	return (
		<div className={getClasses()} data-testid='InputWrapper'>
			<InputLabel
				labelClass={props.labelClass}
				required={props.required}
				for={props.id}
				srOnlyLabel={props.srOnlyLabel}
				moreInfo={props.moreInfo}
				inline={props.inline}
			>
				{props.label}
				{props.includesIcon && (
					<Tooltip tooltipFor='term' description={props.includesIcon.description}>
						<label htmlFor=''>
							<Icon size={"sm"} icon={props.includesIcon.icon} />
						</label>
					</Tooltip>
				)}
			</InputLabel>

			{props.children}

			{!!props.footnote && !props.invalid && <InputFootnote>{props.footnote}</InputFootnote>}

			{!!props.invalid && !!props.invalidMessage && (
				<InputInvalidMessage>* {props.invalidMessage}</InputInvalidMessage>
			)}
		</div>
	);
};

export default InputWrapper;
