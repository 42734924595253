import React, { useState } from "react";
import { randomString } from "../../helper/string.helpers";
import InputWrapper from "../InputWrapper/InputWrapper";
import "./InputTextArea.style.scss";

/**
 * @typedef {Object} InputTextareaProps
 * @property {string} [id] - The ID for the textarea
 * @property {string} [className] - Additional Class(es) to add to the Component
 * @property {string} [label] - The label to appear above the textarea
 * @property {boolean} [srOnlyLabel] - Whether the label should be screen-reader only
 * @property {boolean} [inline] - Whether the textarea should be inline
 * @property {string} [moreInfo] - Additional information to display
 * @property {string} [footnote] - Footnote to display below the textarea
 * @property {"default" | "gray-outline"} [theme] - The theme of the textarea
 * @property {boolean} [required] - Whether the textarea is required
 * @property {boolean} [invalid] - Whether the textarea is invalid
 * @property {string} [invalidMessage] - Message to display when textarea is invalid
 * @property {string} [placeholder] - Placeholder text
 * @property {boolean} [readonly] - Whether the textarea is readonly
 * @property {boolean} [disabled] - Whether the textarea is disabled
 * @property {string} [value] - The value of the textarea
 * @property {number} [maxLength] - Maximum length of the textarea content
 * @property {function} [onValueChange] - Callback function when value changes
 */

const defaultProps = {
	theme: "default",
	srOnlyLabel: false,
	inline: false,
	required: false,
	readonly: false,
	disabled: false,
	value: "",
	onValueChange: (v) => v,
};

const InputTextarea = (props) => {
	props = { ...defaultProps, ...props };
	const [id] = useState(props.id || randomString());

	const getClasses = () => {
		const classes = ["input-textarea"];
		props.className && classes.push(props.className);
		props.theme && classes.push(`theme-${props.theme}`);
		return classes.join(" ");
	};

	const handleChange = (e) => {
		const { value } = e.target;
		if (props.maxLength && value.length > props.maxLength) return;
		props.onValueChange(value);
	};

	return (
		<InputWrapper
			id={id}
			theme={props.theme}
			label={props.label}
			srOnlyLabel={props.srOnlyLabel}
			required={props.required}
			moreInfo={props.moreInfo}
			footnote={props.footnote}
			inline={props.inline}
			invalid={props.invalid}
			invalidMessage={props.invalidMessage}
			labelClass={props.labelClass}
		>
			<div className={getClasses()}>
				<textarea
					id={id}
					className={props.invalid ? "is-invalid" : ""}
					placeholder={props.placeholder}
					readOnly={props.readonly}
					disabled={props.disabled}
					value={props.value || ""}
					onChange={handleChange}
					maxLength={props.maxLength}
				/>
				{props.maxLength && (
					<div className='character-count'>
						{(props.value || "").length}/{props.maxLength}
					</div>
				)}
			</div>
		</InputWrapper>
	);
};

export default InputTextarea;
