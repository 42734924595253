import React from "react";
import Accordion from "../../Accordion/Accordion";
import { checkValueIsPositiveDecimal } from "../../../utils/constants";
import { addCommas, convertToPercent } from "../../../helper/number.helpers";
import InputText from "../../InputText/InputText";
import { handleMonetaryChange, formatMonetaryValue } from "../CreateCloneBusinessInsightsDashboard.helper";
function OfficeFields({ handleBlur, handleFocus, index, locationDescription, handleOnDiscriptionChange }) {
	const getFieldValue = (fieldName) => {
		if (!locationDescription[index]) return "";

		switch (fieldName) {
			case "building_sf":
			case "available_sf":
			case "gross_price_sf":
				return locationDescription[index][fieldName] ? addCommas(locationDescription[index][fieldName]) : "";
			case "percent_leased":
				return locationDescription[index][fieldName]
					? convertToPercent(locationDescription[index][fieldName])
					: "";
			default:
				return locationDescription[index][fieldName] || "";
		}
	};

	return (
		<Accordion collapsedTitle='Show all office fields' expandedTitle={"Hide all office fields"}>
			<InputText
				className={locationDescription[index]?.building_sf ? "hasFocus" : ""}
				type='text'
				name='building_sf'
				label='Building SF'
				onFocus={() => handleFocus({ target: { name: "building_sf" } })}
				onBlur={() => handleBlur({ target: { name: "building_sf" } })}
				value={getFieldValue("building_sf")}
				onValueChange={(value) =>
					handleOnDiscriptionChange("building_sf", checkValueIsPositiveDecimal(value), index)
				}
			/>

			<InputText
				className={locationDescription[index]?.year_built ? "hasFocus" : ""}
				type='text'
				name='year_built'
				label='Year built'
				onFocus={() => handleFocus({ target: { name: "year_built" } })}
				onBlur={() => handleBlur({ target: { name: "year_built" } })}
				value={getFieldValue("year_built")}
				onValueChange={(value) =>
					handleOnDiscriptionChange("year_built", checkValueIsPositiveDecimal(value), index)
				}
			/>

			<InputText
				className={locationDescription[index]?.available_sf ? "hasFocus" : ""}
				type='text'
				name='available_sf'
				label='Available SF'
				onFocus={() => handleFocus({ target: { name: "available_sf" } })}
				onBlur={() => handleBlur({ target: { name: "available_sf" } })}
				value={getFieldValue("available_sf")}
				onValueChange={(value) =>
					handleOnDiscriptionChange("available_sf", checkValueIsPositiveDecimal(value), index)
				}
			/>

			<InputText
				className={locationDescription[index]?.parking_ratio ? "hasFocus" : ""}
				type='text'
				name='parking_ratio'
				label='Parking Ratio'
				onFocus={() => handleFocus({ target: { name: "parking_ratio" } })}
				onBlur={() => handleBlur({ target: { name: "parking_ratio" } })}
				value={getFieldValue("parking_ratio")}
				onValueChange={(value) => handleOnDiscriptionChange("parking_ratio", value, index)}
			/>

			<InputText
				className={locationDescription[index]?.location_suite ? "hasFocus" : ""}
				type='text'
				name='location_suite'
				label='Location Suite'
				onFocus={() => handleFocus({ target: { name: "location_suite" } })}
				onBlur={() => handleBlur({ target: { name: "location_suite" } })}
				value={getFieldValue("location_suite")}
				onValueChange={(value) => handleOnDiscriptionChange("location_suite", value, index)}
			/>

			<InputText
				className={locationDescription[index]?.percent_leased ? "hasFocus" : ""}
				type='text'
				name='percent_leased'
				label='Percent Leased'
				onFocus={() => handleFocus({ target: { name: "percent_leased" } })}
				onBlur={() => handleBlur({ target: { name: "percent_leased" } })}
				value={getFieldValue("percent_leased")}
				onValueChange={(value) =>
					handleOnDiscriptionChange("percent_leased", checkValueIsPositiveDecimal(value), index)
				}
			/>

			<InputText
				className={locationDescription[index]?.base_rent ? "hasFocus" : ""}
				type='text'
				name='base_rent'
				label='Base Rent'
				onFocus={() => handleFocus({ target: { name: "base_rent" } })}
				onBlur={() => {
					handleBlur({ target: { name: "base_rent" } });
					const currentValue = locationDescription[index]?.base_rent;
					if (currentValue) {
						const formattedValue = formatMonetaryValue(currentValue);
						handleOnDiscriptionChange("base_rent", formattedValue, index);
					}
				}}
				value={
					locationDescription[index]?.base_rent
						? `$${addCommas(String(locationDescription[index].base_rent).split(".")[0])}${
								String(locationDescription[index].base_rent).includes(".")
									? "." + String(locationDescription[index].base_rent).split(".")[1]
									: ""
						  }`
						: ""
				}
				onValueChange={(value) => handleMonetaryChange(value, index, handleOnDiscriptionChange, "base_rent")}
			/>

			<InputText
				className={locationDescription[index]?.electric_price_sf ? "hasFocus" : ""}
				type='text'
				name='electric_price_sf'
				label='Electric Price SF'
				onFocus={() => handleFocus({ target: { name: "electric_price_sf" } })}
				onBlur={() => handleBlur({ target: { name: "electric_price_sf" } })}
				value={
					locationDescription[index]?.electric_price_sf
						? `$${addCommas(String(locationDescription[index].electric_price_sf).split(".")[0])}${
								String(locationDescription[index].electric_price_sf).includes(".")
									? "." + String(locationDescription[index].electric_price_sf).split(".")[1]
									: ""
						  }`
						: ""
				}
				onValueChange={(value) =>
					handleMonetaryChange(value, index, handleOnDiscriptionChange, "electric_price_sf")
				}
			/>
			<InputText
				className={locationDescription[index]?.gross_price_sf ? "hasFocus" : ""}
				type='text'
				name='gross_price_sf'
				label='Gross PSF'
				onFocus={() => handleFocus({ target: { name: "gross_price_sf" } })}
				onBlur={() => {
					handleBlur({ target: { name: "gross_price_sf" } });
					const currentValue = locationDescription[index]?.gross_price_sf;
					if (currentValue) {
						const formattedValue = formatMonetaryValue(currentValue);
						handleOnDiscriptionChange("gross_price_sf", formattedValue, index);
					}
				}}
				value={
					locationDescription[index]?.gross_price_sf
						? `$${addCommas(String(locationDescription[index].gross_price_sf).split(".")[0])}${
								String(locationDescription[index].gross_price_sf).includes(".")
									? "." + String(locationDescription[index].gross_price_sf).split(".")[1]
									: ""
						  }`
						: ""
				}
				onValueChange={(value) =>
					handleMonetaryChange(value, index, handleOnDiscriptionChange, "gross_price_sf")
				}
			/>
		</Accordion>
	);
}

export default OfficeFields;
