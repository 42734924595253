/** converts an array to a string formatted as CSS */
export function toCSSString(params) {
	return params && params.length > 0
		? Object.keys(params)
				.map((key) => `${key}:${params[key]}`)
				.join(";")
		: null;
}

/** Converts any string to kebab-case */
export function toKebabCase(str) {
	return str
		.replace(/([a-z])([A-Z])/g, "$1-$2") // get all lowercase letters that are near to uppercase ones
		.replace(/[\s_]+/g, "-") // replace all spaces and low dash
		.toLowerCase(); // convert to lower case
}

/** converts a string to a url friendly slug */
export function slugify(str) {
	return str
		.toString()
		.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "") // removes special characters
		.replace(/([a-z])([A-Z])/g, "$1-$2") // get all lowercase letters that are near to uppercase ones
		.replace(/[\s_]+/g, "-") // replace all spaces and low dash
		.toLowerCase(); // convert to lower case
}

/** normalizes string for comparison */
export function normalize(str) {
	return str.toString().trim().toLowerCase().normalize().replace(/\s/g, "");
}

/** Returns a random string */
export function randomString(len = 16) {
	let result = "";
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
	const charactersLength = characters.length;

	for (let i = 0; i < len; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}

	return result;
}

/** Checks to see if input is a string */
export function isString(x) {
	return typeof x === "string";
}

/** removes all spaces from string */
export function removeWhitespace(str) {
	return str.replace(/ /g, "");
}

/**
 * title case
 * @see https://github.com/blakeembrey/change-case/blob/master/packages/title-case/src/index.ts
 */
const SMALL_WORDS =
	/\b(?:an?d?|a[st]|because|but|by|en|for|i[fn]|neither|nor|o[fnr]|only|over|per|so|some|tha[tn]|the|to|up|upon|vs?\.?|versus|via|when|with|without|yet)\b/i;
const TOKENS = /[^\s:–—-]+|./g;
const WHITESPACE = /\s/;
const IS_MANUAL_CASE = /.(?=[A-Z]|\..)/;
const ALPHANUMERIC_PATTERN = /[A-Za-z0-9\u00C0-\u00FF]/;

export function titleCase(input) {
	let result = "";
	let m;

	while ((m = TOKENS.exec(input)) !== null) {
		const { 0: token, index } = m;

		if (
			// Ignore already capitalized words.
			!IS_MANUAL_CASE.test(token) &&
			// Ignore small words except at beginning or end.
			(!SMALL_WORDS.test(token) || index === 0 || index + token.length === input.length) &&
			// Ignore URLs.
			(input.charAt(index + token.length) !== ":" || WHITESPACE.test(input.charAt(index + token.length + 1)))
		) {
			// Find and uppercase first word character, skips over *modifiers*.
			result += token.replace(ALPHANUMERIC_PATTERN, (m) => m.toUpperCase());
			continue;
		}

		result += token;
	}

	return result;
}

/**
 * Converts a string to a sanitized ID-safe format.
 * - Converts to lowercase
 * - Replaces spaces and special characters with underscores
 * - Handles multiple consecutive spaces/special characters
 * - Trims leading/trailing underscores
 *
 * @param {string} str - The string to sanitize
 * @returns {string} - The sanitized string
 *
 * @example
 * sanitizeId("Available SF") // returns "available_sf"
 * sanitizeId("Landlord/Developer") // returns "landlord_developer"
 * sanitizeId("  Multiple   Spaces  ") // returns "multiple_spaces"
 */
export const sanitizeId = (str) => {
	if (!str) return "";

	return str
		.toLowerCase() // Convert to lowercase
		.replace(/[^a-z0-9]+/g, "_") // Replace special chars with underscore
		.replace(/^_+|_+$/g, ""); // Trim leading/trailing underscores
};

/**
 * Tests if a string has been properly sanitized for use as an ID
 *
 * @param {string} str - The string to test
 * @returns {boolean} - Whether the string matches sanitization rules
 */
export const isValidSanitizedId = (str) => {
	// Only lowercase letters, numbers and single underscores between words
	return /^[a-z0-9]+(?:_[a-z0-9]+)*$/.test(str);
};
